.footer {
    margin-top: 6rem;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.footer__container {
    padding: 4rem 0 6rem;
}

.footer__title,
.footer__link {
    color: var(--title-color);
}

.footer__title {
    text-align: center;
    margin-bottom: var(--mb-2);
}

.footer__link {
    font-weight: var(--font-medium);
    transition: 300ms;
}

.footer__link:hover {
    color: var(--title-color-dark);
}

.footer__list {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
}

.footer__social {
    display: flex;
    justify-content: center;
    column-gap: 2rem;
}

.footer__social-link {
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: 300ms;
}

.footer__social-link:hover {
    color: var(--title-color-dark);
}

.footer__copy {
    display: block;
    color: var(--text-color);
    font-size: var(--smaller-font-size);
    text-align: center;
    z-index: 1001;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
}

/* Air animation for large devices */
@media screen and (min-width: 769px) {
    /* Air animation */
    .air {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: url(../../assets/wave.png);
        background-size: 1000px 100px;
    }

    .air1 {
        animation: wave 30s linear infinite;
        z-index: 1000;
        opacity: 1;
        animation-delay: 0s;
        bottom: 0;
    }

    .air2 {
        animation: wave2 15s linear infinite;
        z-index: 999;
        opacity: 0.5;
        animation-delay: -5s;
        bottom: 10px;
    }

    .air3 {
        animation: wave 30s linear infinite;
        z-index: 998;
        opacity: 0.2;
        animation-delay: -2s;
        bottom: 15px;
    }

    .air4 {
        animation: wave2 5s linear infinite;
        z-index: 997;
        opacity: 0.7;
        animation-delay: -5s;
        bottom: 20px;
    }

    @keyframes wave {
        0% {
            background-position-x: 0px;
        }
        100% {
            background-position-x: 1000px;
        }
    }

    @keyframes wave2 {
        0% {
            background-position-x: 0px;
        }
        100% {
            background-position-x: -1000px;
        }
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
    .footer__list li:nth-child(even) {
        display: none;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
}
