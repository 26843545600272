.music__container {
    display: flex;
    justify-content: center;
    /* background-color: #009875;
    height: 150px;
    width: 80; */
}

.music__container img {
    z-index: 10;
    transition: 300ms;
}

.music__container img:hover {
    transform: translateY(-5px);
}
