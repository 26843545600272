.scrollup {
    position: fixed;
    right: 3rem;
    bottom: -20%;
    background-color: var(--title-color);
    opacity: 0.8;
    padding: 0.3rem 0.5rem;
    border-radius: 2rem;
    z-index: var(--z-tooltip);
    transition: 300ms;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
}

.scrollup:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%), 0 3px 12px rgb(0 0 0 / 10%);
}

.scrollup__icon {
    font-size: 2rem;
    color: var(--container-color);
}

.show-scroll {
    bottom: 2.5rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .scrollup {
        display: none;
    }
}
