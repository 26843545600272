.skills__container {
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.skills__content {
    background-color: var(--container-color);
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 2rem 2rem;
    border-radius: 2rem;
    z-index: 10;
    transition: 300ms;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
}

.skills__content:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%), 0 3px 12px rgb(0 0 0 / 10%);
}

.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-semi-bold);
    text-align: center;
    margin-bottom: var(--mb-1-5);
}

/* .skills__box {
    display: flex;
    justify-content: center;
} */

.skills__group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
    column-gap: 2.5rem;
    justify-items: center;
}

.skills__data {
    display: flex;
    column-gap: 0.5rem;
    /* justify-content: center; */
}

.skills svg {
    height: 20px;
    width: 20px;
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .skills__container {
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
}

/* For medium devices */
@media screen and (max-width: 576px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__content {
        padding: 1.5rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .skills__name {
        font-size: var(--small-font-size);
    }
}
