.contact__container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
    align-items: stretch;
}

.contact__content {
    display: flex;
    flex-direction: column;
}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
}

.contact__card {
    background-color: var(--container-color);
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 2rem;
    text-align: center;
    z-index: 10;
    transition: 300ms;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
}

.contact__card:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%), 0 3px 12px rgb(0 0 0 / 10%);
}

.contact__card-icon {
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data {
    font-size: var(--small-font-size);
}

.contact__card-title {
    font-weight: var(--font-medium);
}

.contact__card-data {
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact__button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact__button-icon {
    font-size: 1.5rem;
    transition: 300ms;
}

.contact__button:hover .contact__button-icon {
    transform: translateX(5px);
}

.contact__form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 360px;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px var(--input-border-color);
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
    background-color: var(--body-color);
    color: var(--text-color);
    outline: none;
    border-radius: 2rem;
    padding: 1.5rem;
    z-index: var(--z-modal);
}

.contact__form-input:focus {
    border: solid 2px var(--title-color-dark);
}

.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: var(--z-tooltip);
}

.contact__form-area {
    height: 10rem;
}

.contact__form-area textarea {
    resize: none;
}

.contact__form-button {
    align-self: flex-start;
    margin-top: auto;
}

.contact__form-button-icon {
    margin-left: 0.5rem;
    font-size: 1.25rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .contact__container {
        column-gap: 3rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .contact__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .contact__info {
        justify-content: center;
    }

    .contact__form {
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {
    .contact__info {
        grid-template-columns: 1fr;
    }

    .contact__form {
        width: 100%;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
}
