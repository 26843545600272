.about__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
}

.about__info {
    grid-template-columns: repeat(3, 175px);
    gap: 2rem;
    margin-bottom: var(--mb-2);
}

.about__content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.about__quote {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    font-weight: var(--font-semi-bold);
    position: relative;
    padding: 0 3rem;
}

.about__quote p::before {
    content: '\201C';
    color: var(--title-color-dark);
    font-size: 10rem;
    line-height: 0.5;
    position: absolute;
    left: 3rem;
    z-index: -1;
    opacity: 0.5;
}

.about__box {
    background-color: var(--container-color);
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    text-align: center;
    padding: 1rem 1.25rem;
    z-index: 10;
    transition: 300ms;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
}

.about__box:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%), 0 3px 12px rgb(0 0 0 / 10%);
}

.about__icon {
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
}

.about__title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle {
    font-size: var(--small-font-size);
}

.about__details {
    text-align: left;
    padding: 0 3rem;
}

.about__description {
    margin-bottom: var(--mb-2-5);
}

.about__button-icon {
    font-size: 1.25rem;
    margin-left: var(--mb-0-5);
}

.about__highlight {
    font-weight: var(--font-medium);
    color: var(--title-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .about__content {
        grid-template-columns: 1fr;
    }

    .about__quote p::before {
        font-size: 8rem;
    }

    .about__details {
        text-align: center;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
    .about__info {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .about__box {
        padding: 0.75rem 1rem;
    }

    .about__box:nth-child(3) {
        width: 50%;
        grid-column: 1 / span 2;
        justify-self: center;
    }

    .about__quote {
        padding: 0;
    }

    .about__quote p::before {
        left: -1rem;
    }

    .about__details {
        padding: 0;
    }

    .about__title {
        font-size: var(--normal-font-size);
    }

    .about__subtitle {
        font-size: var(--smaller-font-size);
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
}
