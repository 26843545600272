.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    column-gap: 2rem;
}

.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__logo {
    /* color: var(--body-color);
    background-color: var(--title-color);
    width: 38px;
    height: 38px;
    text-align: center; */
    /* border-radius: 50%; */
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    font-size: 1.5rem;
    margin-right: auto;
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: 300ms;
    position: relative;
}

.nav__link::before {
    transition: 300ms;
    height: 2px;
    content: '';
    position: absolute;
    background-color: var(--title-color-dark);
    width: 100%;
    bottom: -8px;
    opacity: 0;
}

.nav__item {
    text-align: center;
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

/* Active link */
.active-link,
.nav__link:hover {
    color: var(--title-color-dark);
}

.nav__link:hover {
    transform: translateY(-3px);
}

.active-link::before,
.nav__link:hover::before {
    bottom: -4px;
    opacity: 1;
}

/*=============== BREAKPOINTS ===============*/
/* For medium devices */
@media screen and (max-width: 768px) {
    .header {
        top: initial;
        bottom: 0;
    }

    .nav {
        height: var(--header-height);
    }

    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: 300ms;
    }

    .active-link::before,
    .nav__link:hover::before {
        opacity: 0;
    }

    /* Show Menu */
    .show-menu {
        bottom: 0;
    }

    .nav__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        gap: 1rem;
    }

    .nav__icon {
        font-size: 1.2rem;
    }

    .nav__close {
        position: absolute;
        right: 2.3rem;
        bottom: 0.4rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover {
        color: var(--title-color-dark);
    }

    .nav__toggle {
        font-size: 1.5rem;
        cursor: pointer;
        z-index: var(--z-fixed);
    }

    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
}
