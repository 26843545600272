.projects__container {
    width: 80%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.projects__swiper-next,
.projects__swiper-prev {
    background-color: var(--body-color);
}

.projects__swiper-icon {
    color: var(--title-color);
    font-size: 3rem;
}

.projects__card-wrapper {
    padding: 0.25rem;
}

.projects__card {
    background-color: var(--container-color);
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    border-radius: 2rem;
    margin-bottom: var(--mb-2);
    transition: 300ms;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
}

.projects__card:hover {
    transform: translateY(-3px) !important;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%), 0 3px 12px rgb(0 0 0 / 10%);
}

.projects__img {
    width: 100%;
    /* max-height: 356px; */
    height: auto;
    object-fit: cover;
    border: solid 1px var(--input-border-color);
    border-radius: 1rem;
    margin-bottom: var(--mb-1);
}

.projects__name {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
}

.projects__description {
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-2);
}

.projects__description::after {
    content: '';
    display: block;
    margin-top: 10px;
    border-bottom: 1px solid var(--input-border-color);
    margin-bottom: -15px;
}

.projects__tags-container {
    width: 100%;
    margin-bottom: var(--mb-1);
}

.projects__tag {
    color: var(--container-color);
    background-color: var(--title-color-dark);
    display: inline-block;
    padding: 0.25rem 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 50px;
    font-weight: var(--font-medium);
    font-size: var(--smaller-font-size);
    border-radius: 2rem;
    margin-right: var(--mb-0-25);
}

.projects__button-container {
    display: flex;
    column-gap: var(--mb-0-75);
    justify-content: flex-end;
}

.projects__button {
    padding: 0.5rem 1rem !important;
    font-size: var(--small-font-size);
    column-gap: var(--mb-0-5);
}

.projects__icon {
    font-size: 1.25rem;
    /* margin-right: var(--mb-0-5); */
}

/* Swiper */
.swiper-pagination {
    margin-bottom: -0.25rem;
}

.swiper-pagination-bullet {
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--title-color-dark) !important;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .projects__container {
        width: initial;
        margin-left: 2.25rem;
        margin-right: 2.25rem;
    }

    .projects__card {
        padding: 1.25rem 1.5rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .projects__swiper-icon {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .projects__button-text {
        display: none;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .projects__container {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }
}
