.qualification__container {
    max-width: 768px;
}

.qualification__tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin-bottom: var(--mb-2);
}

.qualification__button {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
    transition: 300ms;
    /* display: inline-block; */
    position: relative;
}

.qualification__button::before {
    transition: 300ms;
    height: 2.5px;
    content: '';
    position: absolute;
    background-color: var(--title-color-dark);
    width: 100%;
    bottom: -5px;
    left: 2px;
    opacity: 0;
}

.qualification__button:hover {
    transform: translateY(-3px);
}

.qualification__active,
.qualification__button:hover {
    color: var(--title-color-dark);
}

.qualification__active::before,
.qualification__button:hover::before {
    bottom: 0px;
    opacity: 1;
}

.qualification__icon {
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}

.qualification__sections {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
}

.qualification__content {
    display: block;
}

.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__data:nth-child(odd) {
    text-align: right;
    justify-items: flex-end;
}

.qualification__data img {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%), 0 3px 12px rgb(0 0 0 / 10%);
}

.qualification__data:nth-child(odd) img {
    order: 2;
}

.qualification__headline {
    display: flex;
    column-gap: 1rem;
}

.qualification__data:nth-child(odd) .qualification__headline {
    justify-content: end;
}

.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification__calendar {
    font-size: var(--small-font-size);
}

.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--title-color);
    border-radius: 50%;
    z-index: var(--z-tooltip);
    position: relative;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .qualification__container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .qualification__container {
        margin-left: var(--mb-2-5);
        margin-right: var(--mb-2-5);
    }

    .qualification__data img {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .qualification__sections {
        grid-template-columns: initial;
    }

    .qualification__button {
        margin: 0 var(--mb-0-75);
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .qualification__data {
        gap: 0.5rem;
    }
}
